@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(20, 40, 43), rgb(20, 40, 43));
  ;
  word-spacing: 0.1em
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  /* Bold weight for headings */
}

p {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  /* Regular weight for body text */
}